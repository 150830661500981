import axios from 'axios';
// import host from '../ipaddress'
import host from '../components/ipaddress';

// axios.defaults.baseURL = 'http://159.65.152.211:8002';



class AuthService {
       constructor(){
       
       }
    
    login(credentials){
      
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'JWT fefege...',
          }
         return axios.post(host+"/v1/mpgps/rkp_finance/" , credentials, {
            headers: headers
        
          });
        
        //return axios.post(USER_API_BASE_URL + "generate-token", credentials);
    
    }

    generateOtp(credentials){

       // return axios.post(host+"v3/accounts/generate_login_web_otp/" , credentials);
       
       return axios.post(host+"/v1/mpgps/rkp_finance/generate_login_web_otp/", credentials);
       
       // console.log(credentials)
   }
         
   
     setToken(idToken) {
        // console.log(idToken)
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Retrieves the user token from localStorage
        return localStorage.getItem('id_token')
    }
    _checkStatus(response) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
            return response
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    
    getAuthHeader() {
       return {headers: {Authorization: 'Bearer ' + this.getUserInfo().token }};
    }
    fetch(url, options) {
        // console.log(url)
        // console.log(options)
        // performs api calls sending the required authentication headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers['Authorization'] = 'Bearer ' + this.getToken()
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }
   
    logOut() {
        localStorage.removeItem("userInfo");
        return axios.post(host+"v3/accounts/verify_otp_creds/" + 'logout', {}, this.getAuthHeader());
    }
}

export default new AuthService();
