import React, { Component } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css"
import { FaMapMarker } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import Icon from '../icon';
import {Form, Input, FormGroup, Container, Label} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import Modal from 'react-responsive-modal';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";
import ReactExport from "react-export-excel";
import moment from 'moment';
import { Link , Redirect } from 'react-router-dom';

import matchpointlogo from '../assets/images/matchpointlogo.png';
import logout from '../assets/images/logout.png';
import orange from '../assets/images/orange.png';
import '../main.css'
import { render } from "react-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import Dialog from 'react-dialog'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { CSVLink, CSVDownload } from "react-csv";
import Background from '../assets/images/New_Project.png';
import ReactTooltip from "react-tooltip";
import "react-table/react-table.css";
import axios from "axios";
import Flag from "react-flags";
import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";
import host from './ipaddress';
import Common_function from './Common_function';
import { osName,osVersion,browserName,deviceType } from 'react-device-detect';
import ApiUrlCall from './Api/apiurl';



const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var gl_requestcode;
var gl_requesttype;
var gl_orderno;
var gl_posts = [];
var gl_list = [];
var gl_req_data = []
var timercounter = 0;
var fnl_req_code;
var fnl_req_type;
var gl_filtervalue;

function new_get_current_address(d_list,indexxx,leng){

  //debugger
  if(leng > 0)
  {

//    localStorage.setItem('gl_list_00',d_list[0]['serial_no'])
    console.log(d_list)
    // if (indexxx==0)
    // { gl_req_data = [] } // reset gl_req_data
   if(typeof d_list[indexxx]!== 'undefined'){
     let serial_number=d_list[indexxx]['serial_no'];
     let row_no=d_list[indexxx].index
     let col_id=d_list[indexxx].col_id
     let ajax_is_updated=false
     delete d_list[indexxx]

  console.log("Hi mohan")
  //var self = this;
  timercounter = row_no; //timercounter*row_no;
  if(ajax_is_updated==false)
  {
  //setTimeout(() => {
  $.ajax({
    url:this.state.host+'/v1/mpgps/rkp_finance/tracker_latest_current_address/',
    dataType: 'json',
    type:'post',
    data:JSON.stringify({"serial_number":serial_number,"row_no":row_no, "column_id":col_id}),
    beforeSend: function(request) {
      request.setRequestHeader("authorization", localStorage.getItem("token"));
      request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
    },
    success: function(resp) {

        console.log("get_current_address SAVED");
        console.log(">>get_current_address",resp);

        if(resp.data.length == 0){
          console.log("Data Not Found")
       }else{
         //update value of cell here by row and colsdfgh
         console.log(">>>> else reps called ..");
         var index = resp.row_no;
         var column = resp.column_id;
         //let data = gl_posts;
         console.log("reac table data : ",resp.data);
         // <div id={cellInfo.column.id+"_"+cellInfo.index}>Loading ...</div>;
         if (resp.data !== 'undefined'){

           console.log("resp.data[0]",resp.data[0])

            if (resp.data[0].current_address !== 'undefined'){

              console.log("current_location indxe ",index)
              if (typeof gl_posts[index] !== 'undefined' )
              {
              gl_posts[index]['current_location'] = resp.data[0].current_address;
              gl_posts[index]['ajax_updated'] = true
              gl_posts[index]['tracker_status'] = resp.data[0].tracker_status
              gl_posts[index]['last_active'] = resp.data[0].last_active
              }
           }
           else{console.log("error at resp.data",resp.data)}
       }
       }
       new_get_current_address(d_list,indexxx+1,leng-1)
    },
    error: function(err){
        console.log("ERROR SAING REMARK",err);
        new_get_current_address(d_list,indexxx+1,leng-1)
    }

});
}
else{
  console.log("else called")
  if(ajax_is_updated==true){
        return gl_posts[row_no][col_id]
  }
  else{
  console.log("else else called")
  };
  }
}
}
else {
  console.log("state change called")
  console.log(this.state.is_reactajax_called,"is_reactajax_called")
  gl_req_data = []
  this.setState({is_reactajax_called:!this.state.is_reactajax_called})

 }
}

class Dashboardtable extends Component{
  constructor(props){
    super(props);

    const token = localStorage.getItem("token")

        let loggedIn = true
        if(token == null){
          loggedIn = false
        }
      this.state={
        loggedIn,

        historydata:[],
        common_message:'',
        value: '',
        date: null,
        focused: null,
        open: false,
        weekperiod:'',
        monthperiod:'',
        range:'',
        textareaValue: '',
        favoritecolor: "red",
        userGroups: localStorage.getItem('user_groups'),

        //host:'http://localhost:8000',
        //host:'http://157.245.106.11:8000',
        //host:'https://lenderdbbackend01.do-blr.mpgpsdc.com',
        host : host,
        filtered: [],
        filterAll: "",
        // filtervalue:'',
        totalcount:'',
        totaldata:'',
        isDialogOpen: false,
        is_reactajax_called : false
      }
      this.handleClick2 = this.handleClick2.bind(this);
      this.renderEditable = this.renderEditable.bind(this);
      this.updateCellAjax = this.updateCellAjax.bind(this);
      this.setIdDiv = this.setIdDiv.bind(this);
      this.current_address.bind(this);
      new_get_current_address = new_get_current_address.bind(this);

  }

  openDialog = () => this.setState({ isDialogOpen: true })

  handleClose = () => this.setState({ isDialogOpen: false })

  onRowClick = (state, rowInfo, instance) => {
    return {
      onClick: (e, handleOriginal) => {
        if (handleOriginal) {
          handleOriginal();
        }
        $(this).remove();
      }
    };
  };

  gettotalcount(responsedata){
    console.log("gettotalcount",responsedata.length)
    return responsedata.length;
  }

  getDashboardData = (submitflag = false) =>{
  if(submitflag){
    fnl_req_type = "weekly";
    fnl_req_code =  "currentweek";
    var data1 = JSON.stringify({'req_type':fnl_req_type,'req_code':fnl_req_code });
  }
  else
  {
    fnl_req_code = gl_requestcode;
    fnl_req_type = gl_requesttype
    var data1 = JSON.stringify({'req_type': fnl_req_type,'req_code':fnl_req_code,'start_date':this.state.startDate,'end_date':this.state.endDate})
  }

    $.ajax({
      url: this.state.host+'/v1/mpgps/rkp_finance/lender_dashboard/',
      dataType: 'json',
      type:'post',
      data:data1,
      beforeSend: function(request) {
          request.setRequestHeader("authorization", localStorage.getItem("token"));
          request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
          $("#loading-image").show();
          $(".loading-icon").show();
        },
      success: function(data) {
        console.log('success',data.data);
        console.log("@@@@@@@@@@@@@@@@@@@",gl_posts)
      var  totaldata = data.data.length
      console.log("^^^^^^^^^^^^^",totaldata)
        // this.homeaddress();
        $("#loading-image").hide();
        $(".loading-icon").hide();
        console.log("aaa")
        gl_posts = data.data;
        this.setState({
            totalcount:data,

          });
        if(data.is_success == true){
            if(data.length == 0){
               alert("Data Not Found")
            }
        }else{
            if(data.response_code == 500){
                alert("Internal server Error")
            }else if(data.response_code == 401){
                 alert("Unauthorised user")
            }else{
                alert(data.response_message)
            }
        }
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
        console.log('fail');
        // alert("Internal Server Error");
        $("#loading-image").hide();
        $(".loading-icon").hide();
        if(err.toString() == "Forbidden"){
         //this.logoutHandler()
        }
      }.bind(this)
    });
  }

  componentDidMount(){
    $('#month').hide();
    $('#all').hide()
    this.getDashboardData(true);
    var user1 = localStorage.getItem("username")
    this.setState({ user1 });
    console.log("getusername",user1);
  }

    handleClick2(value) {
   this.props.history.push('/map?id=true', {"data":value});
 }

  onOpenModal = () => {
      this.setState({ open: true });
    };

    save_remarks(serial_number,remark,on_date){
      console.log(remark, serial_number);
      $.ajax({
        url: this.state.host+'/v1/mpgps/rkp_finance/update_remarks/',
        dataType: 'json',
        type:'post',
        data:JSON.stringify({"serial_number":serial_number, "remark":remark,"on_date":on_date }),
        beforeSend: function(request) {
          request.setRequestHeader("authorization", localStorage.getItem("token"));
          request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
        },
        success: function(data) {
            console.log("REMARK SAVED");
            if(data.length == 0){
              alert("Data Not Found")
           }
        },
        error: function(err){
            console.log("ERROR SAING REMARK")
            // alert("Internal Server Error")
        }
    });

      };
   chunkArray(myArray, chunk_size){

        var index = 0;
        var arrayLength = myArray.length;
        var tempArray = [];

        for (index = 0; index < arrayLength; index += chunk_size) {
           var myChunk = myArray.slice(index, index+chunk_size);
            // Do something if you want with the group
            tempArray.push(myChunk);
        }

        return tempArray;

      }
set_data_for_ajax(serial_no,index,col_id,is_ajaxcalled)
{
  //console.log("is_ajaxcalled ",is_ajaxcalled, serial_no)
  var check_ajaxcalled = gl_posts[index].ajax_updated
  console.log("gl_posts.length ",gl_posts.length , 'check_ajaxcalled ',check_ajaxcalled , serial_no , 'is_ajaxcalled',is_ajaxcalled , 'gl_req_data.length ' ,gl_req_data.length , 'index',index)
  if(check_ajaxcalled == false)
  {
    //debugger

    let check_index = gl_req_data[index]
    if (typeof check_index !== "undefined")
    {
    let check_ajax = gl_req_data[index].is_ajaxcalled
    if (check_ajax==false)
    {

    gl_req_data.push({'serial_no':serial_no,'index':index,'col_id':col_id,'is_ajaxcalled':true})
    console.log("befor ajax called ",index+2," is ",gl_posts.length)
    if (gl_posts.length == index+2)
    {
      console.log(gl_req_data)
      // one row take 83 second to add in datatable eg. 5000 take 1 minute to load
      let ajaxtime = gl_req_data.length * 40
      if (ajaxtime > 120000)
      { ajaxtime =  120000}
      setTimeout(() => {
      new_get_current_address(gl_req_data,0,gl_req_data.length)
      },ajaxtime);


    }
    else{
      //console.log(gl_list.length , index)
      // localStorage.setItem('gl_listlength',gl_posts.length)
      //         localStorage.setItem('index',index)
    }
    return <div id={"upid_"+index+"_"+col_id}>Loading .</div>
  }
  //else { return <div id={"upid_"+index+"_"+col_id}>Loading ..</div> }
}
else {
  gl_req_data.push({'serial_no':serial_no,'index':index,'col_id':col_id,'is_ajaxcalled':true})
  if (gl_posts.length == index+2)
    {
      console.log(gl_req_data)
      // one row take 83 second to add in datatable eg. 5000 take 1 minute to load
      let ajaxtime = gl_req_data.length * 40
      if (ajaxtime > 120000)
      { ajaxtime =  120000}
      setTimeout(() => {
      new_get_current_address(gl_req_data,0,gl_req_data.length)
      },ajaxtime);


    }
  return <div id={"upid_"+index+"_"+col_id}>Loading ...</div> }
  }
  else{
    return gl_posts[index][col_id];
  }

}


get_current_address(serial_number,row_no,col_id,ajax_is_updated){

  // console.log("serial_no" + serial_number+" >>> "+gl_posts);
  //alert(11)
  console.log("Hi mohan")
  var self = this;
  timercounter = row_no; //timercounter*row_no;
  if(ajax_is_updated==false)
  {
  setTimeout(() => {
  $.ajax({
    url: this.state.host+'/v1/mpgps/rkp_finance/tracker_latest_current_address/',
    dataType: 'json',
    type:'post',
    data:JSON.stringify({"serial_number":serial_number,"row_no":row_no, "column_id":col_id}),
    beforeSend: function(request) {
      request.setRequestHeader("authorization", localStorage.getItem("token"));
      request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
    },
    success: function(resp) {
        console.log("get_current_address SAVED");
        console.log(">>get_current_address",resp);

        if(resp.data.length == 0){
          console.log("Data Not Found")
       }else{
         //update value of cell here by row and colsdfgh
         console.log(">>>> else reps called ..");
         var index = resp.row_no;
         var column = resp.column_id;
         let data = gl_posts;
         console.log("reac table data : ",resp.data);
         // <div id={cellInfo.column.id+"_"+cellInfo.index}>Loading ...</div>;
         if (resp.data[0] !== 'undefined'){
           // debugger;
         data[index][column] = resp.data[0].current_address;

         data[index].ajax_updated = true
         $("#upid_"+index+"_"+col_id).text(resp.data[0].current_address);
         $("#tracker_status_"+index).text(resp.data[0].tracker_status);
         $("#last_active_"+index).text(resp.data[0].last_active);
         //console.log("mac",data)
       }
       }
    },
    error: function(err){
        console.log("ERROR SAING REMARK",err);

    }
});

  },timercounter);
  let z= <div id={"upid_"+row_no+"_"+col_id}>Loading ....</div>
  return z
}
else{
  console.log("else called")
  if(ajax_is_updated==true){
        return gl_posts[row_no][col_id]
  }
  else{
  //   let z= <div id={"upid_"+row_no+"_"+col_id}>Loading ....</div>
  // return z
  console.log("else else called")
  };
  }
}
current_address(serial_number,row_no,col_id,ajax_is_updated){

  // console.log("serial_no" + serial_number+" >>> "+gl_posts);
  //alert(11)
  console.log("Hi mohan")
  var self = this;
  timercounter = row_no; //timercounter*row_no;
  if(ajax_is_updated==false)
  {
  setTimeout(() => {
  $.ajax({
    url: this.state.host+'/v1/mpgps/rkp_finance/tracker_latest_current_address/',
    dataType: 'json',
    type:'post',
    data:JSON.stringify({"serial_number":serial_number,"row_no":row_no, "column_id":col_id}),
    beforeSend: function(request) {
      request.setRequestHeader("authorization", localStorage.getItem("token"));
      request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
    },
    success: function(resp) {
        console.log("get_current_address SAVED");
        console.log(">>get_current_address",resp);

        if(resp.data.length == 0){
          console.log("Data Not Found")
       }else{
         //update value of cell here by row and colsdfgh
         console.log(">>>> else reps called ..");
         var index = resp.row_no;
         var column = resp.column_id;
         //let data = gl_posts;
         console.log("reac table data : ",resp.data);
         // <div id={cellInfo.column.id+"_"+cellInfo.index}>Loading ...</div>;
         if (resp.data !== 'undefined'){

           console.log("resp.data[0]",resp.data[0])

            if (resp.data[0].current_address !== 'undefined'){

              console.log("current_location indxe ",index)
              if (typeof gl_posts[index] !== 'undefined' )
              {
              gl_posts[index]['current_location'] = resp.data[0].current_address;
              gl_posts[index]['ajax_updated'] = true
              gl_posts[index]['tracker_status'] = resp.data[0].tracker_status
              gl_posts[index]['last_active'] = resp.data[0].last_active

                $("#upid_"+index+"_"+column).text(resp.data[0].current_address);
                $("#tracker_status_"+index).text(resp.data[0].tracker_status);
                $("#last_active_"+index).text(resp.data[0].last_active);
              }
           }
           else{console.log("error at resp.data",resp.data)}
         //console.log("mac",data)
       }
       }
    },
    error: function(err){
        console.log("ERROR SAING REMARK",err);

    }
});

  },timercounter);
  let z= <div id={"upid_"+row_no+"_"+col_id}>Loading ....</div>
  return z
}
else{
  console.log("else called")
  if(ajax_is_updated==true){
        return gl_posts[row_no][col_id]
  }
  else{
  console.log("else else called")
  };
  }
}



    onCloseModal = () => {
      this.setState({ open: false });
    };


modalfunc(e, serial_number, modalfor, gl_orderno, gl_cust_name){
  console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%",gl_cust_name)

if(modalfor == "history_click"){
  console.log("History Click");

let sdate = moment(this.state.startDate).format('YYYY-MM-DD');
let edate = moment(this.state.endDate).format('YYYY-MM-DD');
let params = JSON.stringify({'serial_number':serial_number,
                            'req_type':fnl_req_type,'req_code':fnl_req_code,
                            'start_date':sdate,
                            'end_date':edate});
console.log("!!!!!!!!!!!!!!!!!!!!!",this.state.startDate);

console.log("RRRRRRRRRRRRRRRRRRRRr");

 $.ajax({
  url: this.state.host+'/v1/mpgps/rkp_finance/show_history/',
  dataType: 'json',
  type:'post',
  data:params,
  beforeSend: function(request) {
    request.setRequestHeader("authorization", localStorage.getItem("token"));
    request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
    $("#loading-image").show();
    $(".loading-icon").show();
    },
  success: function(data) {
    $(".common_message_class").hide();
   console.log("???????????????????????????",data)
    if(data.is_success == true){
      $("#loading-image").hide();
      $(".loading-icon").hide();


      if(data.length == 0){
        alert("Data Not Found")
     }
     else{
        this.setState({
        historydata: data.data.history,
        open: true,
        gl_orderno: gl_orderno,
        gl_cust_name: gl_cust_name
      });
      setTimeout(function(){
         $("#common_message").hide();
      },50)
      }
    }else{
        if(data.response_code == 500){
            alert("Internal server Error")
        }else if(data.response_code == 401){
             alert("Unauthorised user")
        }else{
            alert(data.response_message)
        }
    }
  }.bind(this),
  error: function(xhr, status, err) {
    console.log("xhr",err)
    console.error(this.props.url, status, err.toString());
    console.log('fail');
    alert("Internal Server Error");
    $("#loading-image").hide();
    $(".loading-icon").hide();
    if(err.toString() == "Forbidden"){
     //this.logoutHandler()
    }
  }.bind(this)
});
}

if(modalfor == "remove_click"){
  this.setState({
    common_message: serial_number
   });
  this.setState({
     open: true
   });
   setTimeout(function(){
      $("#history_table").hide();
   },50)

}
}


//dashboard data table
submitHandler = (e) => {
  // const url = "https://jsonplaceholder.typicode.com/posts";
  // fetch(url,{
  //   method:"GET"
  // }).then(response => response.json()).then(posts => {
  //   console.log("posts",posts)
  //   this.setState({
  //     posts:posts,


  //   })
  // })
    if(gl_requesttype == "weekly"){
      alert("Please select Period");
    }
    if(gl_requesttype == "weekly" && gl_requestcode == "currentweek",gl_requesttype == "weekly" && gl_requestcode == "previousweek"){
      this.getDashboardData();
    }
    if(gl_requesttype == "weekly" && gl_requestcode == "twoweekback",gl_requesttype == "weekly" && gl_requestcode == "threeweekback"){
      this.getDashboardData();
    }
    e.stopPropagation();
    // console.log("zz >> ",this.state)
    console.log("%%%%%%%%%%%%%%%",this.state.startDate);
}


searchHandler = (e) => {


  console.log("$$$$$$$$$$$$$$$$$$",gl_filtervalue)

  if(gl_filtervalue != ""){

  console.log(123)
    let data2 = JSON.stringify({'order_no':gl_filtervalue})

  $.ajax({
    url: this.state.host+'/v1/mpgps/rkp_finance/global_search/',
    dataType: 'json',
    cache: false,
    method:'post',
    data:data2,
    beforeSend: function(request) {
      request.setRequestHeader("authorization", localStorage.getItem("token"));
      request.setRequestHeader('api-head', JSON.stringify({"user_id":localStorage.getItem("user"),"mobile_number":localStorage.getItem("mobile_number")}));
      $("#loading-image").show();
      $(".loading-icon").show();
    },
    success: function(data) {
      console.log(345)
      $("#loading-image").hide();
      $(".loading-icon").hide();
      console.log('success',data.data);
      if(data.is_success == true){
        gl_posts = data.data
        gl_req_data = []
        this.setState({
          is_reactajax_called: !this.state.is_reactajax_called
        });
      }else{
          if(data.response_code == 500){
              alert("Internal server Error")
          }else if(data.response_code == 401){
               alert("Unauthorised user")
          }else{
              alert(data.response_message)
          }
      }
    }.bind(this),
    error: function(xhr, status, err) {
      console.error(this.props.url, status, err.toString());
      console.log('fail');
      alert("Internal Server Error");
      $("#loading-image").hide();
    }.bind(this)
  });
}
}


clickMe(event, tracker, vname ){
    // alert("Clickme")
    // const url = '/map',;
    // this.props.history.push('/map?id=true&data='+tracker);
    console.log("this.props ",this.props)
    // this.props.history.push('/map?id=true&data='+tracker);
     window.open('/map?id=true&data='+tracker+"&vname="+vname, '_blank');
  }

logoutHandler =(e) =>{
  var user_id = localStorage.getItem("user")
  var sha256 = require('js-sha256');
  var userid_enc = sha256(user_id);

  var active_session = {
    start_time : Common_function.ServerDateTime(localStorage.getItem("cache focus date")),
    end_time: Common_function.ServerDateTime(new Date())
  }
   console.log(active_session ,"logout time detail")
  var device_detail = [{
   event_id : 1,
  unique_id : userid_enc,
    os_version : osVersion,
    model_name: osName,
    value : active_session,user_id:user_id,
  
  }]
  
  const analytics_details = {
    data : device_detail , source : 2
  };

  
 JSON.stringify(analytics_details)

//  console.log("hello..", analytics_details)
        
  ApiUrlCall.send_analytics(analytics_details).then(response => {
     
    if (response.status === 200) {
      return

    }
  }
  )
  console.log("*****")
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("mobile_number");
  localStorage.setItem('cache focus date','')

  gl_requestcode="";
  gl_requesttype="";
  gl_orderno="";
  gl_posts = [];
  gl_list = [];
  gl_req_data = []
  timercounter = 0;
  this.props.history.push('/');
}

handledata = (e) => {
  // this.setState({requesttype: e.target.value})
  gl_requesttype = e.target.value;
  // this.setState({[e.target.name]: e.target.value})
  console.log("******"+gl_requesttype);
  if(gl_requesttype == "weekly"){
    $('#week').show();
    $('#month').hide();
    $('#all').hide()
  }
  if(gl_requesttype == "monthly"){
    $('#week').hide();
    $('#month').show();
    $('#all').hide()
  }
  if(gl_requesttype == "all"){

    $('#week').hide();
    $('#month').hide();
    $('#all').show()
  }
}

handlesearchdata = (e) => {
console.log("******filtervalue",e.target.value)
gl_filtervalue = e.target.value;


  this.setState({gl_filtervalue: e.target.value})


  this.searchHandler()
   e.stopPropagation()
}


changeHandler = (e) => {
  gl_requestcode = e.target.value;

  console.log("$$$$$"+gl_requestcode)
  this.setState({gl_requestcode: e.target.value})
}

Print = (e) => {
window.print();
}


deleteRow(id){
  const index = gl_posts.findIndex(post =>{
    return post.id === id
  })
  let copyPosts = [...gl_posts]
     copyPosts.splice(index,1)

   this.setState({posts:copyPosts})
}
buttonClick(){
    console.log("came here")
    var id = $(this).attr('id');
    console.log(id)

}
himohan(this_obj){
  console.log("this",this_obj)
  console.log("this",this_obj.original.serial_no)
  var sr_no = this_obj.original.serial_no;
}

handleChange(e) {
  console.log("$$$$$$$$$$$$$$$$$$$$",e)
  this.setState({textareaValue: e.target.value});
}


setIdDiv(cellInfo){

  if (cellInfo.original.ajax_updated==false)
  {
    let z = <div id={cellInfo.column.id+"_"+cellInfo.index}>Loading ...</div>;
    return z;
  }
  else{
    return gl_posts[cellInfo.index][cellInfo.column.id];
  }
}
updateCellAjax(cellInfo){
  console.log("updateCellAjax called");
  return(
    this.current_address(cellInfo.original.serial_no,cellInfo.index,cellInfo.column.id,cellInfo.original.ajax_updated)
  )
}
renderEditable(cellInfo) {
  return (
    <div className="textarea-field"
      contentEditable
      suppressContentEditableWarning
      onBlur={e => {
        console.log("event ",e.target.value, "target",e.target)
        const data = [...gl_posts];
        var remark_srno = data[cellInfo.index].serial_no
        console.log('remark_srno',remark_srno)
        data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
        var remark_new_value = e.target.innerHTML;
        $("#react-tabs-0").focus()
        // this.setState({ data });
        //console.log("mac",data)
        var on_date = data[cellInfo.index].rpt_on_date
        this.save_remarks(remark_srno,remark_new_value,on_date)
      }}
      dangerouslySetInnerHTML={{
        __html: gl_posts[cellInfo.index][cellInfo.column.id]
      }}
    />
  );
}

    render(){
      if(this.state.loggedIn === false){
          return <Redirect to="/" />
        }


      const { data } =  gl_posts ;
      const { open } = this.state;
      const { weekperiod, monthperiod } = this.state
      // const { filtervalue }= this.state

      const headers = [
        { label: "Sr No.", key: "row_sr_no" },
        { label: "Order No.", key: "lan_no" },
        { label: "Customer Name", key: "cust_name" },
        { label: "Mobile Number", key: "mobile_no" },
        { label: "Home Location", key: "home_location" },
        { label: "Tracker Status", key: "tracker_status" },
        { label: "Last Active", key: "last_active" },
        { label: "Current Location", key: "current_location" },
        { label: "Last Ignition Off Time", key: "ignition_off_time" },
        { label: "Location Flag Count", key: "location_count" },
        { label: "Distance Flag Count", key: "distance_count" },
        { label: "Remarks", key: "remark" }
      ];



      const columns=[
    {
      Header: <Tooltip title="Sr No.">Sr No.</Tooltip>,
      accessor: "row_sr_no",
      style:{
        textAlign:"center"
      },
      Cell: (row) => { return <div>{row.index+1}</div>},
      width:100,
      minWidth:100,
      maxWidth:100
    },
    {
      Header: <Tooltip title="Order No.">Order No.</Tooltip>,
      accessor: "lan_no",
      style:{
        textAlign:"center"
      },
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
      width:100,
      minWidth:100,
      maxWidth:100,
    },
    {
      Header: <Tooltip title="Customer Name">Customer Name</Tooltip>,
      accessor: "cust_name",
      sortable:true,
      style:{
        textAlign:"center",
        // 'whiteSpace': 'unset'
      },
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>}
    },
    {
      Header: <Tooltip title="Mobile Number">Mobile Number</Tooltip>,
      accessor: "mobile_no",
      sortable:true,
      style:{
        textAlign:"center",
        // 'whiteSpace': 'unset'
      },
      Cell: row => <div><span title={row.value}>{row.value}</span></div>
    },
    {
      Header: <Tooltip title="Home Location">Home Location</Tooltip>,
      accessor: "home_location",
      sortable:true,
      style:{
        textAlign:"center",
        // 'whiteSpace': 'unset'
      },
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>}
    },
    {
      Header: <Tooltip title="Tracker Status">Tracker Status</Tooltip>,
      accessor: "tracker_status",
      style:{
        textAlign:"center",
        // 'whiteSpace': 'unset'
      },
      // Cell:this.setIdDiv,
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
      sortable:true,
    },
    {
      Header: <Tooltip title="Last Active">Last Active</Tooltip>,
      accessor: "last_active",
      style:{
        textAlign:"center",
        // 'whiteSpace': 'unset'
      },
      // Cell:this.setIdDiv,
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
      sortable:true,
    },
    {
      Header: <Tooltip title="Current Location">Current Location</Tooltip>,
      accessor: "current_location",
      style:{
        textAlign:"center",
      },
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
      sortable:true
      // Cell:this.updateCellAjax,
    },
    {
      Header: <Tooltip title="Last Ignition Off Time">Last Ignition Off Time</Tooltip>,
      accessor: "ignition_off_time",
      style:{
        textAlign:"center"
      },
      Cell: row =>{return <div><span title={row.value}>{row.value}</span></div>},
      sortable:true
    },
    {
      Header: <Tooltip title="Location Flag Count">Location Flag Count</Tooltip>,
      accessor: "location_count",
      style:{
        textAlign:"center",
        float:'right',
        fontSize:'12px',
        fontWeight:'bold'
      },

      Cell:props => {
        return(
           <button style={{backgroundColor:"#fff",border:"none",pointer:"default",pointerEvents:"none"}}>{props.original.location_count}<img src={orange} alt="orange" width="30"/></button>
        )
      },

  	  sortable:true,
    },
    {
      Header: <Tooltip title="Distance Flag Count">Distance Flag Count</Tooltip>,
      accessor: "distance_count",
      style:{
        textAlign:"center",
        float:'right',
      },
      Cell:props => {
        return(
           <button style={{backgroundColor:"#fff",border:"none",pointer:"default",pointerEvents:"none"}}>{props.original.distance_count}<img src={orange} alt="orange" width="30"/></button>
        )
      },
      sortable:true,
    },
    {
      Header: <Tooltip title="Live Track">Live Track</Tooltip>,
      accessor:"live_track",
      style:{
        textAlign:"center",
        cursor:"pointer",

      },
      Cell:props => {
        return(
           <button className="map-btn" value={props.value} onClick={(e) => {
             this.clickMe(e,props.original.tracker_no,props.original.vehicle_model)
           }}><FaMapMarker className="orange"/></button>
        )
      },
      sortable:true,
    },
    {
        Header: <Tooltip title="Remove from Report">Remove from Report</Tooltip>,
        id:'delete',
        accessor: str => "delete",
        Cell: (row)=> (
        <button className="btn btn-dark"
          onClick={(e) => {
              console.log("row.index",row.index);
              let data = gl_posts;
              console.log("row********",row);
              console.log("gl_posts")
              console.log("*******",gl_posts[row.index]);
              var order_no = gl_posts[row.index].lan_no;
              var serial_no = gl_posts[row.index].serial_no;
              var a = data.splice(row.index, 1)
              let rm = gl_posts.splice(row.index, 1)
              this.setState({data})
              this.modalfunc(e.target.value, order_no + " is removed from report",'remove_click')

            }}
            >
              Remove
            </button>
    ),
        sortable:true,
      },
    {
      Header: <Tooltip title="Remarks">Remarks</Tooltip>,
      accessor:"remark",
      filterMethod: (filter, row) => {
                    return row[filter.id].includes(filter.value);
                  },
      style:{
        textAlign:"center",
        'whiteSpace': 'unset',
      },
      Cell: this.renderEditable,
      sortable:true,
      width:250,
      minWidth:250,
      maxWidth:250
    },
    {
      Header: <Tooltip title="History">History</Tooltip>,
      accessor:"history",
      filterMethod: (filter, row) => {
                    return row[filter.id].includes(filter.value);
                  },
      style:{
        textAlign:"center"
      },
      Cell:props => {
        return(

        <button className="btn btn-dark"
        onClick={(e)=>{this.modalfunc(e, props.value,'history_click',props.original.lan_no,props.original.cust_name)}
        }
        >History</button>
        )
      },
      sortable:true,
      width:100,
      minWidth:100,
      maxWidth:100
    },
  ]
        return (
          <div>
          {/*header component start*/}
          <div className="navbar navbar-default">
            <div className="container-fluid pl-0">
                 <div className="navbar-header">
                    <a className="header-logo">
                    <Link to="/dashboard"><img src={matchpointlogo} alt="matchpointlogo" width="300" className="logo"/></Link>
                    </a>
                 </div>
              <div className="nav navbar-nav navbar-right">
              <div className=" list-inline mb-0">
              <div className="list-inline-item fs-21 fc-red text-capitalize border-right">
                Welcome, {this.state.user1}<span className="color-orange"></span>
              </div>
              <div className="list-inline-item">
                  <a onClick={e=>this.logoutHandler(e)} className="cursor-pointer">
                    <img src={logout} alt="logout-logo" width="30"/>
                  </a>
              </div>
              </div>
              </div>
          </div>
        </div>
        {/*header component end*/}



          {/* Range component start*/}
          <div className="container-fluid pt-35 red1">
            <div className="row plr-20 ">
              <div>


             <Modal  open={open} onClose={this.onCloseModal} center>
            <div id="common_message">
            <p className="common_message_class">{this.state.common_message}</p>
            </div>
           <div id="history_table" className="cls_history_table">
           <div className="row">
             <div className="col-sm-6">
             <div className="order-no"><span>CUSTOMER NAME: </span><span className="marr-20 color">{this.state.gl_cust_name}</span><span>ORDER NO: </span><span className="color">{this.state.gl_orderno}</span></div>
             </div>
             <div className="col-sm-6">
             <button className="btn btn-dark print-btn" onClick={this.Print}>Print</button>
           <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Export Excel Report"/>
             </div>
           </div>
           <table className="table table-bordered" id="table-to-xls">
                  <thead>
                    <tr className="text-center">
                      <th className="gray">Date</th>
                      <th className="gray">Parked Location (parked between midnight to 4 AM)</th>
                      <th className="gray">Distance Covered (kms)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.historydata.map( (item,i) => {
                     return(
                        <tr key={i} className="text-center">
                        <td>{item['date']}</td>
                        <td>{item['last_parked_loc']}</td>
                        <td>{item['distance_travelled']}</td>
                        </tr>
                        )
                    })
                    }
                  </tbody>
                </table>
                </div>
                <h2 className="selected_id">
                </h2>
             </Modal>
             </div>

             <div className="col-lg-3">
             <div className="input-group search-input resp-input-group">
             <input type="text" onBlur={this.handlesearchdata} className="form-control custom-serach-input" placeholder="Order no" />
             <FaSearch className="search-icon" onClick={this.searchHandler} />
             </div>
             </div>

              <div className="col-lg-9">
              <div className="list-inline" style={{float:"right"}}>
              <Tabs>
              <TabList className="float-align gray-shadow">
                <Tab>
                <button type="button" className="btn_weekly" id="weekly_id" value="weekly" onClick={this.handledata}>Weekly</button>
                </Tab>
                <Tab>
                <button type="button" className="btn_monthly" id="monthly_id" value="monthly" onClick={this.handledata}>Monthly</button>
                </Tab>
                <Tab>
                <button type="button" className="btn_all" id="all_id" value="all" onClick={this.handledata}>All</button>
                </Tab>
              </TabList>
              <TabPanel>
              <span className="period-text">Period:</span>
              <select id="weekly_select" className="weekly list-inline-item gray-shadow" onChange={this.changeHandler}>
              <option  value="currentweek" name="a">Current Week</option>
              <option  value="previousweek" name="a">Previous Week</option>
              <option value="twoweekback" name="a"> Last 2 Weeks </option>
              <option value="threeweekback" name="a">Last 3 Weeks </option>
              </select>
              </TabPanel>
              <TabPanel>
              <span className="period-text">Period:</span>
              <select className="monthly list-inline-item gray-shadow" onChange={this.changeHandler}>
              <option>Select Month</option>
              <option value="jan" name="weekly">January</option>
              <option value="feb" name="weekly">Feb</option>
              <option value="march">March</option>
              <option value="april">April</option>
              <option value="may">May</option>
              <option value="june">June</option>
              <option value="july">July</option>
              <option value="august">August</option>
              <option value="september">September</option>
              <option value="october">October</option>
              <option value="november">November</option>
              <option value="december">December</option>
              </select>
              </TabPanel>
              <TabPanel>
              <span className="period-text">Period:</span>
              <Container className="all list-inline-item gray-shadow daterangepicker-list-item pd-2" >
                <Form className="pd-3">
                  <DateRangePicker
                      startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                      endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                      onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                      focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                      onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                      isOutsideRange={day => (moment().diff(day) < 0)}

                    />
                </Form>
              </Container>
              </TabPanel>
              <button type="submit" id="submit_id" className="btn btn-dark submit-btn" onClick={this.submitHandler}>Submit</button>
              <ExcelFile element={<button className="btn btn-md btn-dark export-btn" onClick={this.ExcelFileLoader}>Export Excel Report</button>}>
                <ExcelSheet data={gl_posts} name="Reports">
                <ExcelColumn label='Sr No.' value="row_sr_no"/>
                <ExcelColumn label='Order No.' value="lan_no"/>
                <ExcelColumn label='Customer Name' value="cust_name"/>
                <ExcelColumn label='Mobile Number' value="mobile_no"/>
                <ExcelColumn label='Home Location' value="home_location"/>
                <ExcelColumn label='Tracker Status' value="tracker_status"/>
                <ExcelColumn label='Last Active' value="last_active"/>
                <ExcelColumn label='Current Location' value="current_location"/>
                <ExcelColumn label='Last Ignition Off Time' value="ignition_off_time"/>
                <ExcelColumn label='Location Flag' value="location_count"/>
                <ExcelColumn label='Distance Flag' value="distance_count"/>
                <ExcelColumn label='Live Track' value="live_track"/>
                <ExcelColumn label='Remarks' value="remark"/>
                </ExcelSheet>
             </ExcelFile>
              </Tabs>
              </div>
              </div>
            </div>
            <br/>
            <p className="text-center red">Total Potential Delinquent Cases In This Report: <span>{gl_posts.length}</span> out of <span>{this.state.totalcount.total_count}</span></p>
            </div>
            {/*Range component end */}



            {/* Dashboardtable component start */}
            <div className="red2">
            <div className="plr-20">
            <ReactTable columns={columns}
                        getTdProps={this.onRowClick}

                        ref={r => (this.reactTable = r)}
                        // onFilteredChange={this.onFilteredChange.bind(this)}
                        // data={gl_posts}
                        data={gl_posts}
                        showPagination={true}

                        defaultPageSize={10}
                        style={{
                          maxHeight: "630px" // This will force the table body to overflow and scroll, since there is not enough room
                        }}
                        >
              </ReactTable>

              <div className="loading-icon" id="myDiv">
              <img id="loading-image" / >
              </div>
            </div>
            </div>
            {/* Dashboardtable component end */}

           </div>
        )
    }
}
export default Dashboardtable
