import axios from 'axios';
import { Component } from 'react';
import { type } from 'jquery';
import host from '../ipaddress'


const header1 = {
    'Authorization': localStorage.getItem('token'),
}
const headers =
{
    'Content-Type': 'application/json',
    'user': localStorage.getItem("user_id"),
    'timezone': 'Asia/Kolkata',
    'token': localStorage.getItem("token")
}

class ApiUrlCall {
    showLosNumber(){
        return axios.post(host+'/v1/mpgps/rkp_finance/get_rkp_finance_data/',{},{headers : header1}) 
    }
    send_analytics(data){
        
        return axios.post(host+'/v1/mpgps/rkp_finance/collect_analytics/',data,{headers:header1})

    }
   
    getApiDocument(){
        return axios.post(host+'/v1/mpgps/rkp_finance/pub_api/doc/',{},{headers : header1})
    }

}

export default new ApiUrlCall();
