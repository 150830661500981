import React from "react";
import {Tabs,Tab,AppBar} from "@material-ui/core";
import Dashboardtable from './components/Dashboardtable';
import Dashboardtable_demo from './components/Dashboardtable_demo';
import NightParking from './components/NightParking';
import TopParkingLoc from './components/TopParkingLoc';
import RKPNRT from "./components/RKPNRT";
import BatteryDisconnectionReport from './components/BatteryDisconnectionReport';
import Today_inst from './components/Today_inst'
import Order_status from './components/order_status'
import CollectionTeam from "./components/CollectionTeam";
import MapPage from './Map';
import matchpointlogo from './assets/images/matchpointlogo.png';
import logout from './assets/images/logout.png';
import orange from './assets/images/orange.png'; 
import { Link , Redirect } from 'react-router-dom';
import Api_integration from "./components/Api_integration";


const Home = props => {
    const { match, history } = props;
    const { params } = match;
    const { page } = params;
  
    const tabNameToIndex = {
      0: "Install",
      1: "Dashboardtable",
      2: "Parking",
      3: "BatteryDisconnectionReport",
      4: "OrderStatus",
      5 : "TopParkingLoc",
      6: "RKPNRT",
      7: "CollectionTeam",
      8: "Api_integration"
    };
  
    const indexToTabName = {
        Install :0,
        Dashboardtable: 1,  
        Parking : 2,
        BatteryDisconnectionReport : 3,
        OrderStatus : 4,
        TopParkingLoc : 5,
        RKPNRT : 6 ,
        CollectionTeam : 7,
        // Api_integration : 8
    };
  
    const [selectedTab, setSelectedTab] = React.useState(indexToTabName[page]);
  
    const handleChange = (event, newValue) => {
      history.push(`/home/${tabNameToIndex[newValue]}`);
      setSelectedTab(newValue);
    };
  
    return (
       
<>
        <AppBar position="static">
          <Tabs value={selectedTab} onChange={handleChange}>
            <Tab label="Install Cases" />
            <Tab label="Delinquent cases" />
            <Tab label="Parking" />
            <Tab label="Battery Disconnect" />
            <Tab label="Order Status" />
            <Tab label="Top Parking Loc" />
            <Tab label="NRT Report" />
            <Tab label="Collection Team"/>
            {/* <Tab label="Api_integration"/> */}

     
          </Tabs>
        </AppBar>
        {selectedTab === 0 && < Today_inst/>}
        {selectedTab === 1 && <Dashboardtable_demo />}
        {selectedTab === 2 && <NightParking />}
        {selectedTab === 3 && <BatteryDisconnectionReport />}
        {selectedTab === 4 && <Order_status />}
        {selectedTab === 5 && <TopParkingLoc />}
        {selectedTab === 6 && <RKPNRT />}
        {selectedTab === 7 && <CollectionTeam />}
        {/* {selectedTab === 8 && <Api_integration />} */}


 </>     

    );
  };
  export default Home;
