//const host = 'http://157.245.106.11:8002'

//const host = "https://lenderdbbackend01.do-blr.mpgpsdc.com"

  const host ="https://rkpfinance-backend.do-blr.mpgpsdc.com"
  //const host ="http://139.59.82.120:8000"


  //const host ="http://139.59.44."

   //const host = "http://157.245.106.11:8000"
  
  export default host
  